body, html{
  margin: 0%;
  margin: 0%;
}



.main-container{
  background-image: url('./assets/background.jpg');
  background-repeat: no-repeat;
  min-height: 100%;
  min-width: 1024px;
    
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
    
    /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  
  
}

.Top-bar{
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(58, 56, 56, 0.795);
  display: flex;
  height: 24px;

}

.Top-items-left{
  color: white;
  box-sizing: border-box;
  width: 50%;
  text-align: left;
  padding-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: row;

}

.Top-items-left .Finder{
  font-weight: bold;
}

.Top-items-left p{
  padding-left: 20px;
  text-decoration: none;
  color: white;
  position: relative;
  bottom: 15px;
  
}


.Top-items .Finder{
  font-weight: bold;
}

#apple-icon{
  padding-top: 2px;
  height: 15px;
  width: 15px;
  object-fit: contain;
}

#topright-icon{
  padding-top: 2px;
  height: 18px;
  width: 20px;
  object-fit: contain;
  margin-left: 11px;
  
}

.Top-items-right{
  color: white;
  padding-left: 750px;
  box-sizing: border-box;
  width: 50%;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Top-items-right .date{
  padding-left: 10px;
}


.task-bar{
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  width: 33%;
  height: 70px;
  right: 33%;
  background-color: rgba(99, 94, 94, 0.582);
  margin: 0 auto;
  border-radius: 10px;
}


.taskbar-items{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.task-bar .taskbar-items input:hover {
  transform: translateY(-15px) scale(1.2);
  transition: all 0.09s linear;
}

.task-bar .taskbar-items img:hover {
  transform: translateY(-15px) scale(1.2);
  transition: all 0.09s linear;
}


#taskbar-icon{
  height: 60px;
  width: 60px;
  margin: 3px;
}


.App1-taskbar{
  cursor: move;
  box-sizing: border-box;
  width: 690px;
  height: 30px;
  background-color: rgb(58, 56, 56);
  position: relative;
  left: 500px;
  top: 300px;
  -webkit-user-drag: element;
  border-radius: 5px;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



#handle{
  padding-left: 110px;
  width: 50px;
  /*color: rgb(58, 56, 56);*/
  color: white;
}


.App1{
  position: absolute;
  box-sizing: border-box;
  top: 28px;
  width: 690px;
  height: 500px;
  background-color: rgb(63, 60, 60);
  display: flex;
}

.dots-wrapper{
  display: flex;
  padding-left: 10px;
  padding-top: 2px;
}

#dot-one{
  background: #FC6058;
  cursor: pointer;
}

#dot-two{
  background: #FEC02F;
  
}
#dot-three{
  background: #2aca3e;
  
}

.browser-dot{
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;

  -webkit-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
  box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
}

.App-name{
  padding-left: 245px;
  padding-top: 3px;
  margin: 0;
  color: white;
}

.All-notes{
  color: white;
  box-sizing: border-box;
  height: 500px;
  width: 225px;
  background-color: rgb(63, 60, 60);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.226);
  border-width: 0.5px;
  border-bottom: none;
  border-top: none;
}

.searchbox-container{
  box-sizing: border-box;
  height: 45px;
  width: 225px;
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.226);
  border-top: none;
  border-right: none;
}

#search input{
  border: 0 none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #777;
  width: 150px;
  padding: 6px 15px 6px 35px;
  margin-left: 10px;
  margin-top: 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

#search input:focus{
  background-color: white;
}

.skills-card{
  box-sizing: border-box;
  height: 65px;
  width: 225px;
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.226);
  border-top: none;
  border-right: none;

}

.skills-card p{
  margin-left: 10px;
}

#one{
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
}

#two{
  font-size: 14px;
  margin: 0;
  padding-left: 10px;
  color: rgb(202, 198, 198);
}

.bignote-container{
  color: white;
  padding-left: 15px;
  display: flex;
  flex-direction: column;

}

.notes{
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  margin-top: -15px;
}

.bignote-container li{
  list-style-type: none;
}

.App2-taskbar{
  
  cursor: move;
  box-sizing: border-box;
  width: 350px;
  height: 30px;
  background-color: rgba(32, 31, 31, 0.76);
  position: relative;
  left: 100px;
  top: 100px;
  -webkit-user-drag: element;
  border-radius: 2px;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App2-taskbar .dots-wrapper{
  margin-top: 5px;
}

/*Total length for app2 is 1450px*/


.App2-left{
  position: absolute;
  box-sizing: border-box;
  top: 30px;
  width: 350px;
  height: 850px;
  background-color: rgba(32, 31, 31, 0.76);
  display: flex;
  flex-direction: column;
}

.App2-left .searchbox-container{
  padding-top: 15px;
  padding-left: 35px;
  border-bottom: none;
  color: white;
}

.App2-left #search input{
  border-radius: 5px;
  width: 200px;
  background-color:rgba(226, 219, 219, 0.219);
  color: rgb(255, 255, 255);
}

.App2-left #search ::placeholder{
  color: rgba(255, 255, 255, 0.692);
}

.catagories-left{
  position: relative;
  color: rgba(255, 255, 255, 0.685);
  margin-top: 85px;
  font-size: 22px;
  right: 35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.update-num{
  position: relative;
  box-sizing: border-box;
  width: 40px;
  height: 20px;
  background-color: rgb(231, 220, 220);
  margin-left: 235px;
  bottom: 43px;
  text-align: center;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.61);
  border-radius: 10px;
  font-weight: 650;
}

.update-num p{
  padding-right: 2px;
}

.catagories-left img{
  padding-right: 15px;
  height: 25px;
  width: 28px;
}

.profile-left{
  display: flex;
  flex-direction: column;
  position: relative;
  color: rgba(255, 255, 255, 0.685);
  right: 250px;
  top: 700px;
}

.profile-left p{
  position: relative;
  top: 30px;
  left: 10px;
  width: 150px;
  margin: 1px;
}

#profile-name{
  font-size: 18px;
}

.profile-left img{
  height: 65px;
  width: 65px;
  position: relative;
  right: 80px;
  top: 90px;
}

.App2-right{
  position: absolute;
  box-sizing: border-box;
  left: 350px;
  width: 1150px;
  height: 880px;
  background-color: rgb(65, 63, 63);
  display: flex;
  color: rgba(255, 255, 255, 0.863);
  
}

.App2-intro{
  position: absolute;
  box-sizing: border-box;
  width: 1050px;
  height: 110px;
  margin-left: 45px;
  margin-top: 10px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;

}

.App2-intro p{
  font-size: 42px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App2-highlights{
  display: flex;
  flex-direction: row;
}

.highlights-left{
  display: flex;
  flex-direction: column;
  position: relative;
  left: 45px;
  top: 145px;
  height: 300px;
}

.highlights-right{
  display: flex;
  flex-direction: column;
  position: relative;
  left: 69px;
  top: 145px;
  height: 300px;
}

.highlights-right img{
  height: 330px;
  width: 530px;
  padding-top: 5px;
  border-radius: 15px;
}

.highlights-left img{
  height: 300px;
  width: 500px;
  padding-top: 5px;
  border-radius: 15px;
}

.App2-highlights p{
  margin: -1px;
}

#text1{
  font-size: 11px;
  color: rgb(0, 162, 255);
}

#text2{
  font-size: 24px;
  font-weight: 400;
}

#text3{
  font-size: 22px;
  color: rgb(175, 175, 175);
}

.bottom-line{
  top: 550px;
  right: 985px;
  position: relative;
  box-sizing: border-box;
  height: 10px;
  width: 1055px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.past-projects{
  position: absolute;
  top: 550px;
  left: 45px;
  font-size: 22px;
  color: rgb(231, 231, 231);
  width: 1050px;
  height: 315px;
  font-weight: 450;
}

.app-row1{
  display: flex;
  flex-direction: row;
  height: 120px;
}

.app1 {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
 .app1 img{
   height: 90px;
   width: 90px;
   border-radius: 20px;
 }

.app1 p{
  position: relative;
  margin: 1px;
  margin-left: 20px;
  left: 100px;
  bottom: 100px;
}

#des1{
  font-size: 17px;
  font-weight: 450;
}

#des2{
  font-size: 15px;
  color: rgb(182, 179, 179);
}

.open-app p{
  padding-top: 3px;
  margin-top: 20px;
  box-sizing: border-box;
  height: 30px;
  width: 70px;
  background-color: #fff;
  color: rgb(110, 108, 108);
  font-size: 15px;
  text-align: center;
  border-radius: 20px;
  font-weight: 650;
  cursor: pointer;
}

.app-line{
  box-sizing: border-box;
  height: 5px;
  width: 190px;
  position: relative;
  bottom: 85px;
  left: 120px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

#app2{
  padding-left: 150px;
}

.app-row2{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 110px;
}

#app3{
  padding-left: 161px;
}
#app4{
  padding-left: 133px;
}

.App-detail{
  box-sizing: border-box;
  width: 1128px;
  height: 860px;
  margin-left: 10px;
  margin-top: 10px;
}

.backarrow{
  width: 35px;
  height: 30px;
  box-sizing: border-box;
  border-color: rgba(255, 255, 255, 0.384);
  border-style: solid;
  border-width: 0.5px;
}

.App-begin{
  box-sizing: border-box;
  width: 1055px;
  height: 250px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}

.App-begin img{
  height: 170px;
  width: 170px;
  border-radius: 30px;
}

.App-begin-details{
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 30px;
  margin-left: 30px;
  
  width: 1000px;
  height: 100px;
}

.App-begin-details p{
  position: relative;
  bottom: 20px;
  margin: 1px;
}

#text2{
 color: rgb(0, 162, 255);
 font-size: 14px;
}

.get-app p{
  padding-top: 4px;
  margin-top: 20px;
  box-sizing: border-box;
  height: 30px;
  width: 70px;
  background-color: #fff;
  color: rgb(110, 108, 108);
  font-size: 15px;
  text-align: center;
  border-radius: 20px;
  font-weight: 650;
  margin-right: 10px;
}

.circle-app{
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  background-color: #fff;
  color: rgb(110, 108, 108);
  position: relative;
  top: 20px;

}

.circle-app p{
  position: relative;
  bottom: 28px;
  left: 5px;
  font-size: 22px;
  font-weight: 650;
}

.App-begin-details-right{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: 200px;
}

.break-line{
  position: relative;
  box-sizing: border-box;
  height: 10px;
  width: 860px;
  margin-left: 240px;
  bottom: 170px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.app-rating{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  position: relative;
  left: 245px;
  bottom: 170px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.stars-contianer{
  display: flex;
  flex-direction: row;
  
}

.stars-contianer img{
  height: 30px;
  width: 35px;
  margin: -15px;
  padding-left: 15px;
  margin-top: 23px;
}

#numOfRatings{
  color: rgba(255, 255, 255, 0.568);
  position: relative;
  bottom: 35px;
  font-size: 16px;
}

#breakline2{
  position: relative;
  box-sizing: border-box;
  height: 10px;
  width: 1055px;
  margin-left: 240px;
  bottom: 270px;
  right: 195px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.app-description{
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  width: 1060px;
  height: 200px;
  bottom: 280px;
  left: 45px;
  
}

.app-description p{
  position: relative;
  bottom: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#breakline3{
  position: relative;
  box-sizing: border-box;
  height: 10px;
  width: 1055px;
  margin-left: 240px;
  bottom: 300px;
  right: 195px;
  border-color: rgba(255, 255, 255, 0.226);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.preview{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 1055px;
  height: 435px;
  bottom: 310px;
  left: 45px;
}

.image-container{
  display: flex;
  flex-direction: row;
}

.image-container img{
  position: relative;
  height: 390px;
  width: 250px;
  margin: 20px;
  bottom: 20px;
  left: 50px;
  border-radius: 10px;
}

.page-click{
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: red;
}

.image-container-two{
  display: flex;
  flex-direction: row;
}

.image-container-two img{
  position: relative;
  height: 330px;
  width: 500px;
  margin: 20px;
  right: 10px;
  border-radius: 10px;
}
 #p2psc1{
   width: 850px;
   height: 370px;
   left: 50px;
   bottom: 10px;
 }

 .snake-game{

   position: relative;
   top: 30px;
   right:  168px;
   box-sizing: border-box;
   height: 570px;
   width: 692px;
   background-color: rgb(44, 43, 43);
 }

 .snake-taskbar{
    cursor: move;
    box-sizing: border-box;
    width: 690px;
    height: 30px;
    background-color: rgb(58, 56, 56);
    position: relative;
    -webkit-user-drag: element;
    border-radius: 2px;
    display: flex;
    left: 550px;
    top: 200px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 .bin-taskbar{
  cursor: move;
  box-sizing: border-box;
  width: 690px;
  height: 30px;
  background-color: rgb(58, 56, 56);
  position: relative;
  -webkit-user-drag: element;
  border-radius: 2px;
  display: flex;
  left: 550px;
  top: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 #snake-name{
   color: white;
   position: relative;
   bottom: 10px;
   left: 220px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }

 #bin-name{
  color: white;
  position: relative;
  bottom: 10px;
  left: 235px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.bin{
  position: absolute;
  box-sizing: border-box;
  height: 470px;
  width: 690px;
  display: flex;
  top: 30px;
  background-color: rgb(63, 60, 60);
}

.cv-container{
  margin-left: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  height: 170px;
  width: 150px;
  background-color: rgb(63, 60, 60);
  
}

#pdf-image{
  width: 160px;
  cursor: pointer;
}

.cv-container:hover{
  box-sizing: border-box;
  height: 180px;
  width: 160px;
  background-color: rgba(255, 255, 255, 0.432);
}

#file-name{
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  position: relative;
  bottom: 37px;
  left: 10px;
}

.safari-taskbar{
  cursor: move;
  box-sizing: border-box;
  width: 1300px;
  height: 50px;
  background-color: rgb(58, 56, 56);
  position: relative;
  -webkit-user-drag: element;
  border-radius: 2px;
  display: flex;
  left: 400px;
  top: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 3px;
}


.safari-taskbar-container{
  display: flex;
  margin-top: 10px;
  flex-direction: row;

}

#safari-arrow{
  position: relative;
  height: 25px;
  width: 30px;
  margin-top: 2px;
  left: 50px;
}

#sidebar{
  height: 25px;
  width: 30px;
  position: relative;
  left: 20px;
  top: 2px;
}

#shield{
  padding-left: 40px;
  height: 20px;
  width: 20px;
  position: relative;
  left: 220px;
  top: 5px;
}

.url{
  box-sizing: border-box;
  width: 390px;
  height: 33px;
  bottom: 2px;
  background-color: rgba(87, 87, 87, 0.733);
  position: relative;
  left: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 8px;
}

#lock{
  height: 15px;
  width: 15px;
  position: relative;
  left: 9px;
  top: 9px;
}

#url-text{
  color: white;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 450;
  position: relative;
  bottom: 7px;
  font-size: 15px;
  left: 15px;
}

#refresh{
  height: 15px;
  width: 15px;
  position: relative;
  left: 99px;
  top: 10px;
}

.safari-taskbar-right{
  position: relative;
  left: 235px;
  top: 3px;
}

.safari-taskbar-right img{
  height: 25px;
  width: 25px;
  margin-left: 20px;
}

#share{
  height: 18px;
  width: 20px;
  margin-bottom: 5px;
}

#plus{
  height: 17px;
  width: 17px;
  margin-bottom: 5px;
}

#tabs{
  height: 17px;
  width: 17px;
  margin-bottom: 5px;
}

.safari-page{
  box-sizing: border-box;
  width: 1300px;
  height: 850px;
  background-color: rgb(51, 51, 51);
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
}
 #social1{
  width: 650px;
  height: 850px;
   cursor: pointer;
 }

 #social2{
  width: 650px;
  height: 850px;
  cursor: pointer;
}

.Message-taskbar{
  cursor: move;
  box-sizing: border-box;
  width: 1200px;
  height: 30px;
  background-color: rgb(58, 56, 56);
  position: relative;
  -webkit-user-drag: element;
  border-radius: 2px;
  display: flex;
  left: 400px;
  
  top: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 2px;
}

.Message-taskbar p{
  margin-left: 215px;
}

.Contact-page{
  box-sizing: border-box;
  width: 1200px;
  height: 750px;
  background-color: rgb(51, 51, 51);
  position: absolute;
  top: 30px;
  display: flex;
  flex-direction: row;
}

.side-message{
  box-sizing: border-box;
  height: 750px;
  width: 300px;
  background-color: rgb(51, 51, 51);
  border-style: solid;
  border-width: 0.5px;
  border-top: none;
  border-color: rgba(255, 255, 255, 0.308);
  display: flex;
  flex-direction: row;
}

.message-card{
  box-sizing: border-box;
  width: 298px;
  height: 140px;
  background-color: rgb(51, 51, 51);
  border-style: solid;
  border-width: 0.5px;
  border-right: none;
  border-top: none;
  border-color: rgba(255, 255, 255, 0.308);

}

#message-icon{
  color: white;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  background-color: grey;
  border-radius: 30px;

  position: relative;
  left: 20px;
  top: 15px;
}

#message-icon p{
  position: relative;
  right: 195px;
  top: 10px;
  font-size: 24px;
  font-weight: 600;
}

.message-label-containers{
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 400px;
  right: 70px;
  bottom: 60px;
}

#message-label-1{
  color: white;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 450;
  position: relative;
  right: 50px;
}

#message-label-2{
  color: rgba(255, 255, 255, 0.486);
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  position: relative;
  right: 35px;
  bottom: 40px;
}

.main-message{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-left: 140px;
}

.contact-form{
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.849);
  margin-top: 70px;
}

.contact-form label{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

.input-field{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(221, 221, 221, 0.925);
  border-radius: 5px;
  border: 1px solid var(--borderColor);
  font-size: 16px;
}

.label{
  margin-bottom: 10px;
}

#submit-btn{
  margin-top: 20px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  background-color: rgb(0, 110, 255);
  border: none;
  border-radius: 10px;

}

#vr-AppsUsed{
  padding-top: 23px;
}